import React, { useState } from 'react';
import { Formik, Form as FormikForm } from 'formik';
import { useMutation, gql } from '@apollo/client';
import styled from 'styled-components';
import { useTranslation, Trans } from 'lib/i18n';
import { saveTokens } from 'lib/withTokenMiddleware';

import { trim } from 'helpers/string';

import Input, { InputError } from 'components/Input/Input';
import Button from 'components/shared/Button/Button';
import { Container, Title, Form } from 'components/Registration/View/View';

import {
  ResetUserPasswordMutation,
  ResetUserPasswordMutationVariables,
} from './__generated__/ResetUserPasswordMutation';

const InputContainer = styled.div`
  margin-bottom: 20px;
`;

const resetUserPasswordMutation = gql`
  mutation ResetUserPasswordMutation($input: ResetUserPasswordInput!) {
    resetUserPassword(input: $input) {
      user {
        id
      }
      authToken {
        tokenType
        accessToken
      }
      refreshToken {
        token
        expireAt
      }
      error {
        code
      }
    }
  }
`;

const ResetPassword: React.FC<{ email: string; token: string }> = ({ email, token }) => {
  const [mutate] = useMutation<ResetUserPasswordMutation, ResetUserPasswordMutationVariables>(
    resetUserPasswordMutation,
  );
  const [submitError, setSubmitError] = useState('');
  const { t } = useTranslation(['common', 'registration']);

  return (
    <Formik
      initialValues={{
        password: '',
        redoPassword: '',
      }}
      validate={values => {
        const errors: { [key: string]: string } = {};

        if (values.password.length < 8) {
          errors.password = t('tooShort');
        }

        if (values.password !== values.redoPassword) {
          errors.redoPassword = t('passwordsDontMatch');
        }

        return errors;
      }}
      onSubmit={({ password }, { setSubmitting }) => {
        setSubmitError('');

        mutate({
          variables: {
            input: {
              email,
              token,
              password,
            },
          },
        })
          .then(({ data }) => {
            if (!data) {
              return setSubmitting(false);
            }

            const {
              resetUserPassword: { authToken, refreshToken, error },
            } = data;

            if (error) {
              setSubmitError(t(`registration:reset-user-password.errors.code.${error.code}`));

              return setSubmitting(false);
            }

            if (saveTokens(authToken, refreshToken)) {
              return window.location.replace('/');
            }

            return setSubmitting(false);
          })
          .catch(() => setSubmitting(false));
      }}
    >
      {({ values, touched, errors, setFieldValue, isSubmitting }) => (
        <Container>
          <Title>
            <Trans i18nKey="registration:reset-user-password.title" />
          </Title>
          <Form>
            <FormikForm>
              <InputContainer>
                <Input
                  name="password"
                  type="password"
                  placeholder={t('newPassword')}
                  autoFocus={true}
                  value={values.password}
                  onChange={e => setFieldValue('password', trim(e.target.value))}
                />
                {touched.password && errors.password && <InputError>{errors.password}</InputError>}
              </InputContainer>
              <InputContainer>
                <Input
                  name="redoPassword"
                  type="password"
                  placeholder={t('redoPassword')}
                  value={values.redoPassword}
                  onChange={e => setFieldValue('redoPassword', trim(e.target.value))}
                />
              </InputContainer>
              {touched.redoPassword && errors.redoPassword && (
                <InputContainer>
                  <InputError>{errors.redoPassword}</InputError>
                </InputContainer>
              )}
              {submitError && (
                <InputContainer>
                  <InputError>{submitError}</InputError>
                </InputContainer>
              )}
              <InputContainer>
                <Button primary={true} disabled={isSubmitting}>
                  {t('changePassword')}
                </Button>
              </InputContainer>
            </FormikForm>
          </Form>
        </Container>
      )}
    </Formik>
  );
};

export default ResetPassword;
