import { useState } from 'react';
import { removeItem } from 'helpers/array';

export interface RegistrationViewsStackProps {
  currentView: string;
  setView: (view: string) => void;
  goBack: () => void;
}

const useRegistrationViewsStack = (initialView = 'signup'): RegistrationViewsStackProps => {
  const [views, setView] = useState([initialView]);
  const currentView = views[views.length - 1];

  const setNextView = (nextView: string) => {
    setView(prevState => [...prevState, nextView]);
  };

  const goBack = () => {
    if (views.length > 1) {
      setView(removeItem(views, views.length - 1));
    }
  };

  return {
    currentView,
    setView: setNextView,
    goBack,
  };
};

export default useRegistrationViewsStack;
