import React, { useEffect } from 'react';
import { Formik, Form as FormikForm } from 'formik';
import { gql, useMutation } from '@apollo/client';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import { useTranslation, Trans } from 'lib/i18n';
import { useTracking } from 'lib/track';
import redirect from 'lib/redirect';

import Input, { InputError } from 'components/Input/Input';
import InputWithLoader from 'components/Input/InputWithLoader';
import { Button } from 'components/shared';
import useSlugAvailability from 'components/workspaces/useSlugAvailability';

import { Container, Title, Note, Form } from 'components/Registration/View/View';

const InputContainer = styled.div`
  margin-bottom: 20px;
`;

const CreateWorkspaceNote = styled.div`
  font-size: 1.4rem;
  color: ${p => p.theme.currentTheme.notes};
  margin-top: 10px;
`;

const InputPrefixContainer = styled.div`
  display: flex;
`;

const InputPrefix = styled.div`
  color: ${p => p.theme.currentTheme.buttonText};
  font-size: 1.6rem;
  letter-spacing: -0.5px;
  align-self: center;
  margin-right: 11px;
  margin-top: -3px;
`;

const SubmitContainer = styled.div`
  margin: 50px 0 10px;
`;

const StyledButton = styled(Button)`
  width: 100%;
`;

const createWorkspaceMutation = gql`
  mutation CreateTeamWorkspaceMutation($input: CreateProjectInput!) {
    createProject(input: $input) {
      project {
        id
        title
        slug
      }
    }
  }
`;

const CreateWorkspace = () => {
  const router = useRouter();
  const { t } = useTranslation(['common', 'registration']);
  const { track } = useTracking('WorkspaceCreation');
  const [mutate] = useMutation(createWorkspaceMutation);
  const availabilityProps = useSlugAvailability();

  useEffect(() => track('', 'Showed'), []);

  return (
    <Formik
      initialValues={{ title: '', slug: '' }}
      validate={values => {
        const errors: { title?: React.ReactNode; slug?: boolean } = {};

        if (!values.title.trim()) {
          errors.title = t('cantBeBlank');
        }

        if (!availabilityProps.isAvailable) {
          errors.slug = true;
        }

        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        if (availabilityProps.isLoading) {
          return setSubmitting(false);
        }

        track('ContinueBtn', 'Clicked');

        return (
          mutate({
            variables: {
              input: values,
            },
          })
            // @ts-ignore FIXME
            .then(({ data: { createProject } }) => {
              router.replace(
                `/workspace?workspaceSlug=${createProject.project.slug}`,
                `/${createProject.project.slug}`,
              );
            })
            .catch(() => {
              track('Error', 'Showed');
              setSubmitting(false);
            })
        );
      }}
      render={({ values, errors, touched, handleChange, isSubmitting }) => (
        <Container>
          <Title>
            <Trans i18nKey="registration:create-workspace.title" />
          </Title>
          <Note>{t('registration:create-workspace.note')}</Note>
          <Form>
            <FormikForm>
              <InputContainer>
                <Input
                  name="title"
                  type="text"
                  placeholder={t('workspaceName')}
                  autoFocus={true}
                  autoComplete="off"
                  value={values.title}
                  onChange={handleChange}
                />

                {touched.title && errors.title && <InputError>{errors.title}</InputError>}

                <CreateWorkspaceNote>
                  {t('registration:create-workspace.workspaceNameHint')}
                </CreateWorkspaceNote>
              </InputContainer>
              <InputContainer>
                <InputPrefixContainer>
                  <InputPrefix>columns.me/</InputPrefix>
                  <InputWithLoader
                    loading={availabilityProps.isLoading}
                    checked={values.slug && availabilityProps.isAvailable}
                    name="slug"
                    type="text"
                    placeholder={t('url')}
                    autoComplete="off"
                    value={values.slug}
                    onChange={availabilityProps.onChange(handleChange)}
                  />
                </InputPrefixContainer>

                {!availabilityProps.isAvailable && <InputError>{t('slugUnavailable')}</InputError>}

                <CreateWorkspaceNote>
                  <Trans i18nKey="registration:create-workspace.workspaceSlugHint" />
                </CreateWorkspaceNote>
              </InputContainer>
              <SubmitContainer>
                <Button primary={true} disabled={isSubmitting} type="submit">
                  {t('continue')}
                </Button>
              </SubmitContainer>
            </FormikForm>
            <StyledButton
              flat={true}
              disabled={isSubmitting}
              onClick={() => {
                track('SkipLink', 'Clicked');
                redirect({}, '/');
              }}
            >
              Skip
            </StyledButton>
          </Form>
        </Container>
      )}
    />
  );
};

export default CreateWorkspace;
