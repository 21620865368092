import { gql, useMutation, MutationHookOptions } from '@apollo/client';

import {
  RequestUserPasswordResetMutation,
  RequestUserPasswordResetMutationVariables,
} from './__generated__/RequestUserPasswordResetMutation';

const requestUserPasswordResetMutation = gql`
  mutation RequestUserPasswordResetMutation($input: RequestUserPasswordResetInput!) {
    requestUserPasswordReset(input: $input) {
      result
    }
  }
`;

const useRequestUserPasswordResetMutation = (
  options?: MutationHookOptions<
    RequestUserPasswordResetMutation,
    RequestUserPasswordResetMutationVariables
  >,
) =>
  useMutation<RequestUserPasswordResetMutation, RequestUserPasswordResetMutationVariables>(
    requestUserPasswordResetMutation,
    options,
  );

export default useRequestUserPasswordResetMutation;
