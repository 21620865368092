import React from 'react';
import styled from 'styled-components';
import Loader from 'components/shared/Loader/Loader';
import CheckedIcon from 'vector/CheckedIcon.svg';

const Container = styled.div`
  position: relative;
  width: 100%;
`;

const LoaderContainer = styled.div`
  position: absolute;
  top: 1px;
  right: 10px;
  bottom: 0px;
  display: flex;
  align-items: center;
`;

export type Props = {
  loading: boolean;
  checked?: boolean;
};

const InputLoadingContainer: React.FC<Props> = ({ loading, checked, children }) => {
  const loadingIcon = loading && <Loader size={17} />;

  return (
    <Container>
      {children}
      <LoaderContainer>{checked ? <CheckedIcon /> : loadingIcon}</LoaderContainer>
    </Container>
  );
};

export default InputLoadingContainer;
