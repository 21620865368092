/* global gtag */

import { googleAds, env } from 'config/env';

export const trackSignUp = () => {
  if (env.production) {
    gtag('event', 'conversion', {
      // eslint-disable-next-line @typescript-eslint/camelcase
      send_to: googleAds.conversionId,
    });
  } else {
    // eslint-disable-next-line no-console
    console.log(`gtag('event', 'conversion', {
  'send_to': '${googleAds.conversionId}',
});`);
  }
};
