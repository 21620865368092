import styled from 'styled-components';
import { mediaQuery } from 'helpers/style-utils';
import { InputError } from 'components/Input/Input';

export const Container = styled.div`
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
`;

export const Title = styled.h1`
  font-size: 5.2rem;
  line-height: 4.7rem;
  letter-spacing: -0.04em;
  margin: 0px 0px 10px;
`;

export const Note = styled.div`
  font-size: 1.6rem;
  line-height: 1.9rem;
  color: ${p => p.theme.currentTheme.notes};
  margin-top: 5px;
`;

export const Form = styled.div<{ contentWidth?: string }>`
  padding-top: 32px;
  max-width: ${props => props.contentWidth || '280px'};
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  ${mediaQuery(580)`
    max-width: 100%;
  `}
`;

export const SignUpError = styled(InputError)`
  text-align: center;
`;
