import React, { Component } from 'react';
import { gql } from '@apollo/client';
import { compose } from 'recompose';
import withApolloClient from 'lib/withApolloClient';

import { withTranslation } from 'lib/i18n';
import { saveTokens } from 'lib/withTokenMiddleware';
import createProjectMembershipFromToken from 'lib/createProjectMembershipFromToken';

import { trackSignUp } from 'lib/googleAds';

const createWorkspaceMutation = gql`
  mutation CreatePersonalWorkspaceMutation($input: CreateProjectInput!) {
    createProject(input: $input) {
      project {
        id
        title
        slug
      }
    }
  }
`;

const userWorkspacesQuery = gql`
  query userWorkspaces {
    currentUser {
      id
      emailConfirmed
      projects {
        id
        title
        slug
      }
    }
  }
`;

const initialState = {
  error: null,
};

// @ts-ignore FIXME
const signUpRedirectContainer = (form: string) => ComposedComponent => {
  class SignUpRedirectContainer extends Component {
    state = initialState;

    // @ts-ignore FIXME
    setError = ({ code }, { email }) => {
      // @ts-ignore FIXME
      const { t } = this.props;
      const error = t(`${form}.errors.code.${code}`, code === 409 ? { email } : {});

      return this.setState({ error });
    };

    // @ts-ignore FIXME
    withErrorReset = func => (...args) => this.setState(initialState, () => func(...args));

    resetForm = () => {
      this.setState(initialState);
    };

    // @ts-ignore FIXME
    onFormSubmitSuccess = (payload, values) => {
      if (payload.error) {
        // @ts-ignore FIXME
        return this.setError(payload.error, values);
      }

      if (saveTokens(payload.authToken, payload.refreshToken)) {
        this.proceedOrRedirect({
          ...values,
          ...payload.user,
        });
      }

      return undefined;
    };

    // @ts-ignore FIXME
    onGoogleLoginSuccess = ({ signinViaProvider: { user, errors = [] } }) => {
      if (errors.length > 0) {
        // @ts-ignore FIXME
        return this.setError(errors);
      }

      if (user) {
        this.proceedOrRedirect(user);
      }

      return undefined;
    };

    // @ts-ignore FIXME
    proceedOrRedirect = async user => {
      if (form === 'signup') {
        trackSignUp();
      }

      // @ts-ignore FIXME
      const { client } = this.props;

      const { data } = await client.query({
        query: userWorkspacesQuery,
        fetchPolicy: 'no-cache',
      });

      const {
        projects,
        projects: [project],
        emailConfirmed,
      } = data.currentUser;

      const projectMembership = await this.createProjectMembershipFromToken();

      // TODO: check if first project is 'personal'
      if (projects.length > 1 || projectMembership) {
        const pathname = projectMembership?.project?.slug || '';

        return window.location.replace(`/${pathname}`);
      }

      if (form === 'signin') {
        return window.location.replace('/');
      }

      const nextView = emailConfirmed ? 'userInfo' : 'confirmEmail';

      if (projects.length === 0) {
        // @ts-ignore FIXME
        return this.createPersonalWorkspace(user).then(({ data: { createProject } }) => {
          this.prepareView(user, createProject.project, nextView);
        });
      }

      return this.prepareView(user, project, nextView);
    };

    createProjectMembershipFromToken = async () => {
      // @ts-ignore FIXME
      const { token } = this.props;

      if (token) {
        // @ts-ignore FIXME
        const { client, setMembership } = this.props;

        try {
          const result = await createProjectMembershipFromToken(client, token);
          const projectMembership =
            result?.data?.createProjectMembershipFromToken?.projectMembership;

          setMembership(projectMembership);

          return projectMembership;
        } catch (e) {
          return null;
        }
      }

      return null;
    };

    // @ts-ignore FIXME
    prepareView(user, workspace, view) {
      // @ts-ignore FIXME
      const { setUser, setWorkspace, setView } = this.props;

      setUser(user);
      setWorkspace(workspace);
      setView(view);
    }

    // @ts-ignore FIXME
    createPersonalWorkspace(user) {
      // @ts-ignore FIXME
      const { client } = this.props;

      return client.mutate({
        mutation: createWorkspaceMutation,
        variables: {
          input: {
            title: user.name,
          },
        },
      });
    }

    render() {
      const { error } = this.state;

      return (
        <ComposedComponent
          resetForm={this.resetForm}
          onFormSubmitSuccess={this.onFormSubmitSuccess}
          onGoogleLoginSuccess={this.withErrorReset(this.onGoogleLoginSuccess)}
          signUpError={error}
          {...this.props}
        />
      );
    }
  }

  // @ts-ignore FIXME
  return compose(
    withApolloClient,
    // @ts-ignore FIXME
    withTranslation('registration'),
    // @ts-ignore FIXME
  )(SignUpRedirectContainer);
};

export default signUpRedirectContainer;
