import React, { useEffect } from 'react';
import { useMutation, gql } from '@apollo/client';
import { Formik, Form as FormikForm } from 'formik';
import styled from 'styled-components';
import { useTracking } from 'lib/track';
import { useTranslation, Trans } from 'lib/i18n';
import redirect from 'lib/redirect';

import { useRegistrationTrackCookie } from 'lib/withRegistrationTrack';

import { trim } from 'helpers/string';

import Input, { InputError } from 'components/Input/Input';
import InputWithLoader from 'components/Input/InputWithLoader';
import { Button } from 'components/shared';
import useSlugAvailability from 'components/workspaces/useSlugAvailability';

import { Container, Title, Note, Form } from 'components/Registration/View/View';

const InputContainer = styled.div`
  margin-bottom: 20px;
`;

const UserInfoNote = styled.div`
  font-size: 1.4rem;
  color: ${p => p.theme.currentTheme.notes};
  padding-top: 0;
  margin-top: 10px;
  text-align: center;
`;

const UserInfoForm = styled(Form)`
  max-width: 280px;
  width: 100%;
  padding-top: 30px;
  margin: 0 auto;
`;

const InputPrefixContainer = styled.div`
  display: flex;
`;

const SubmitContainer = styled.div`
  margin-top: 30px;
  padding: 0 10px;
`;

const StyledTitle = styled(Title)`
  margin: 0px -35px 10px -35px;
`;

const updatePersonalWorkspaceMutation = gql`
  mutation UpdatePersonalWorkspaceMutation($input: UpdateProjectInput!) {
    updateProject(input: $input) {
      project {
        id
        title
        slug
      }
    }
  }
`;

const isWithAt = (string: string) => string.charAt(0) === '@';

// @ts-ignore FIXME
const UserInfo = ({ setView, workspace, membership, token }) => {
  const { track } = useTracking('SignUpIntroduce');
  const { t } = useTranslation(['common', 'registration']);
  const [mutate] = useMutation(updatePersonalWorkspaceMutation);
  const { checkAvailability, ...availabilityProps } = useSlugAvailability(workspace.slug);

  useEffect(() => track('', 'Showed'), []);

  return (
    <Formik
      initialValues={{
        title: workspace.title,
        slug: workspace.slug,
      }}
      validate={values => {
        const errors = {};

        if (!values.title.trim()) {
          // @ts-ignore FIXME
          errors.title = t('cantBeBlank');
        }

        if (!availabilityProps.isAvailable) {
          // @ts-ignore FIXME
          errors.slug = true;
        }

        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        if (availabilityProps.isLoading) {
          return setSubmitting(false);
        }

        track('ContinueBtn', 'Clicked');

        return mutate({
          variables: {
            input: {
              id: workspace.id,
              ...values,
            },
          },
          // @ts-ignore FIXME
        })
          .then(() => {
            useRegistrationTrackCookie(token !== undefined);

            if (membership) {
              return redirect({}, `/${membership.project.slug}`);
            }

            setSubmitting(false);

            if (token) {
              return redirect({}, '/');
            }
            return setView('usageSelect');

            // @ts-ignore FIXME
          })
          .catch(() => {
            setSubmitting(false);
            track('Error', 'Showed');
          });
      }}
      render={({ values, touched, errors, handleChange, setFieldValue, isSubmitting }) => {
        const onSlugChange = (e: React.FormEvent<HTMLInputElement>): void => {
          const { value } = e.target as HTMLInputElement;
          const trimmed = trim(value);

          if (trimmed) {
            checkAvailability(trimmed.slice(1));
          }

          return setFieldValue('slug', trimmed);
        };

        return (
          <Container>
            <StyledTitle>{t('registration:user-info.title')}</StyledTitle>
            <Note>{t('registration:user-info.note')}</Note>
            <UserInfoForm>
              <FormikForm>
                <InputContainer>
                  <Input
                    name="title"
                    type="text"
                    placeholder={t('registration:user-info.firstAndLastName')}
                    autoFocus={true}
                    autoComplete="off"
                    value={values.title}
                    onChange={handleChange}
                  />

                  {touched.title && errors.title && <InputError>{errors.title}</InputError>}
                </InputContainer>
                <InputContainer>
                  <InputPrefixContainer>
                    <InputWithLoader
                      loading={availabilityProps.isLoading}
                      checked={values.slug && availabilityProps.isAvailable}
                      name="slug"
                      type="text"
                      placeholder={t('registration:user-info.username')}
                      value={isWithAt(values.slug) ? values.slug : `@${values.slug}`}
                      onChange={onSlugChange}
                    />
                  </InputPrefixContainer>

                  {!availabilityProps.isAvailable && (
                    <InputError>{t('slugUnavailable')}</InputError>
                  )}

                  <UserInfoNote>
                    <Trans i18nKey="registration:user-info.slugHint" />
                  </UserInfoNote>
                </InputContainer>
                <SubmitContainer>
                  <Button
                    primary={true}
                    disabled={availabilityProps.isLoading || isSubmitting}
                    type="submit"
                  >
                    {t('registration:buttons.submit')}
                  </Button>
                </SubmitContainer>
              </FormikForm>
            </UserInfoForm>
          </Container>
        );
      }}
    />
  );
};

export default UserInfo;
