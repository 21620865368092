import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation, Trans } from 'lib/i18n';
import { useTracking } from 'lib/track';

import { mediaQuery, mediaSelector } from 'helpers/style-utils';

import {
  Container as ViewContainer,
  Title,
  Form as ViewForm,
} from 'components/Registration/View/View';

import TeamIcon from './UseAsTeamIcon.svg';
import UserIcon from './UseAsIndividualIcon.svg';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  align-items: center;
  flex-direction: row;
`;

const StyledTitle = styled(Title)`
  margin-bottom: 0;

  ${mediaQuery(580)`
    width: auto;
    br {
      display: none;
    }
  `}
`;

const Form = styled(ViewForm)`
  max-width: 100%;
`;

const Button = styled.div`
  color: ${p => p.theme.currentTheme.buttonText};
  box-shadow: 0 0 0 1px ${p => p.theme.currentTheme.border};
  box-sizing: border-box;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 144px;
  height: 140px;
  margin: 0 10px;
  cursor: pointer;

  ${mediaSelector.hoverSupported} {
    &:hover {
      box-shadow: 0 0 0 2px ${p => p.theme.currentTheme.active};
    }
  }

  ${mediaQuery(580)`
    font-size: 1.5rem;
    line-height: 1.8rem;
  `}
`;

const Note = styled.div`
  font-size: 1.4rem;
  line-height: 1.7rem;
  color: ${p => p.theme.currentTheme.notes};
  margin-top: 30px;
`;

// @ts-ignore FIXME
const UserInfo = ({ setView }) => {
  const { track } = useTracking('SignUpHowToUse');
  const { t } = useTranslation(['common', 'registration']);

  useEffect(() => track('', 'Showed'), []);

  return (
    // @ts-ignore FIXME
    <ViewContainer>
      <StyledTitle>
        <Trans i18nKey="registration:usage-select.title" />
      </StyledTitle>
      <Form>
        <Container>
          <Button
            onClick={() => {
              track('AsTeam', 'Clicked');
              setView('createWorkspace');
            }}
          >
            <TeamIcon />
            {t('asATeam')}
          </Button>
          <Button
            onClick={() => {
              track('AsIndividual', 'Clicked');
              window.location.replace('/');
            }}
          >
            <UserIcon />
            {t('asAnIndividual')}
          </Button>
        </Container>
        <Note>{t('registration:usage-select.note')}</Note>
      </Form>
    </ViewContainer>
  );
};

export default UserInfo;
