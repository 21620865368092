import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Trans } from 'lib/i18n';
import { useTracking } from 'lib/track';

import { mediaSelector } from 'helpers/style-utils';

import ConfirmNote from 'components/account/confirm-email/Note';
import ConfirmEmailForm from 'components/account/confirm-email/Form';
import { Container, Title } from 'components/Registration/View/View';

const StyledTitle = styled(Title)`
  ${mediaSelector.mobile} {
    width: 315px;
  }
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 460px;
  margin: 0 auto;
`;

const NoteContainer = styled.div`
  margin-bottom: 20px;
  padding: 0 15%;

  ${mediaSelector.mobile} {
    padding: 0;
  }
`;

type Props = {
  user?: {
    email: string;
  };
  setView: (view: string) => void;
};

const ConfirmEmail: React.FC<Props> = ({ user, setView }) => {
  const { track } = useTracking('SignUpConfirmation');

  useEffect(() => {
    track('', 'Showed');
  }, []);

  return (
    <Container>
      <StyledTitle>
        <Trans i18nKey="registration:confirm-email.title" />
      </StyledTitle>
      <FormContainer>
        <NoteContainer>
          <ConfirmNote email={user?.email || ''} />
        </NoteContainer>
        <ConfirmEmailForm
          onEmailConfirmSuccess={() => {
            setView('userInfo');
            track('ConfirmEmail', 'Confirmed');
          }}
          onResendCodeSuccess={() => {
            track('ResendEmail', 'Clicked');
          }}
          onError={() => {
            track('Error', 'Showed');
          }}
        />
      </FormContainer>
    </Container>
  );
};

export default ConfirmEmail;
