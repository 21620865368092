import React from 'react';
import styled from 'styled-components';

import { mediaQuery } from 'helpers/style-utils';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${p => p.theme.currentTheme.body};
  mix-blend-mode: normal;
  opacity: 0.98;
  min-height: 100%;

  ${mediaQuery(580)`
    padding-top: 80px;
    align-items: flex-start;
    opacity: 1;
  `}
`;

const Wrapper = styled.div`
  width: 100%;
  padding: 70px 50px; // 80px 50px 35px;
  margin: 25px;
  display: flex;
  flex-direction: column;

  ${mediaQuery(580)`
    padding: 20px 0;
    margin: 48px 40px;
    opacity: 1;
  `}
`;

const RegistrationView: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <Container>
    <Wrapper>{children}</Wrapper>
  </Container>
);

export default RegistrationView;
