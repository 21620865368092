import { useState, useCallback } from 'react';
import { useApolloClient, gql, ApolloQueryResult } from '@apollo/client';
import debounce from 'lodash/debounce';

import {
  ProjectSlugAvailabilityQuery,
  ProjectSlugAvailabilityQueryVariables,
} from './__generated__/ProjectSlugAvailabilityQuery';

const projectSlugAvailabilityQuery = gql`
  query ProjectSlugAvailabilityQuery($slug: String!) {
    projectSlugAvailability(slug: $slug) {
      result
      error {
        code
      }
    }
  }
`;

type State = {
  isAvailable: boolean;
  isLoading: boolean;
  error?: {
    code: number;
  };
};

type AvailabilityProps = {
  checkAvailability: (slug: string) => void;
  onChange: (arg: any) => void;
} & State;

const useSlugAvailability = (
  originalSlug?: string,
  onFetchSuccess: (
    slug: string,
    result: ApolloQueryResult<ProjectSlugAvailabilityQuery>,
  ) => void = () => {},
): AvailabilityProps => {
  const [state, setState] = useState<State>({
    isAvailable: true,
    isLoading: false,
    error: undefined,
  });
  const client = useApolloClient();

  const getAvailability = (slug: string) => {
    return client
      .query<ProjectSlugAvailabilityQuery, ProjectSlugAvailabilityQueryVariables>({
        query: projectSlugAvailabilityQuery,
        variables: {
          slug,
        },
      })
      .then(result => {
        const { data } = result;

        if (!data) {
          return null;
        }

        const {
          projectSlugAvailability: { result: isSlugAvailable, error },
        } = data;

        setState(prevState => ({
          ...prevState,
          isLoading: false,
          isAvailable: slug === originalSlug && !isSlugAvailable ? true : isSlugAvailable,
          error: error ? { code: error.code } : undefined,
        }));

        if (slug !== originalSlug && isSlugAvailable) {
          onFetchSuccess(slug, result);
        }
      })
      .catch(() => {
        setState(prevState => ({
          ...prevState,
          isLoading: false,
          isAvailable: false,
        }));
      });
  };

  const debouncedGetAvailability = useCallback(debounce(getAvailability, 500), []);

  const checkAvailability = (slug: string) => {
    if (!state.isLoading) {
      setState(prevState => ({
        ...prevState,
        isLoading: true,
      }));
    }

    debouncedGetAvailability(slug);
  };

  const onChange = (arg: any) => {
    if (arg) {
      if (typeof arg === 'function') {
        return (e: React.FormEvent) => {
          onChange(e);
          arg(e);
        };
      }

      if (typeof arg === 'string') {
        return checkAvailability(arg);
      }

      if (arg.target) {
        return checkAvailability(arg.target.value);
      }
    }

    return null;
  };

  return {
    ...state,
    checkAvailability,
    onChange,
  };
};

export default useSlugAvailability;
