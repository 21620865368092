import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form as FormikForm } from 'formik';
import { gql, useMutation } from '@apollo/client';
import styled from 'styled-components';
import { compose } from 'recompose';
import { useTracking } from 'lib/track';
import { withTranslation } from 'lib/i18n';

import { isValidEmail } from 'helpers/forms';
import { currentUserQuery } from 'components/users/WithCurrentUser/WithCurrentUser';
import currentUserFragment from 'components/users/WithCurrentUser/currentUserFragment';

import Input, { InputError } from 'components/Input/Input';
import { Button } from 'components/shared';
import { Container, Title, Note, Form, SignUpError } from 'components/Registration/View/View';
import GoogleLogin from 'components/Registration/GoogleLogin/GoogleLogin';
import signUpRedirectContainer from 'components/Registration/signUpRedirectContainer';

const StyledTitle = styled(Title)`
  font-size: 6.2rem;
  line-height: 5.6rem;
  margin: 0px -20px 10px -20px;
`;

const InputContainer = styled.div`
  margin-bottom: 20px;
`;

const SignUpNote = styled.div`
  font-size: 1.4rem;
  color: ${p => p.theme.currentTheme.notes};
  margin-top: 10px;
`;

const Delimiter = styled.hr`
  margin: 30px 0;
`;

const SignUpErrorContainer = styled.div`
  margin-bottom: 25px;
`;

const GoogleLoginContainer = styled.div`
  padding: 0px;
`;

const signUpMutation = gql`
  mutation SignUpMutation($input: SignupInput!) {
    signup(input: $input) {
      user {
        ...CurrentUserFragment
      }
      authToken {
        accessToken
        tokenType
      }
      refreshToken {
        token
        expireAt
      }
      error {
        code
      }
    }
  }
  ${currentUserFragment}
`;

// @ts-ignore FIXME
const SignUp = ({ signUpError, resetForm, onFormSubmitSuccess, onGoogleLoginSuccess, t }) => {
  const { track } = useTracking('SignUp');
  const [mutate] = useMutation(signUpMutation, {
    update: (cache, { data }) => {
      if (data?.signup?.user) {
        cache.writeQuery({
          query: currentUserQuery,
          data: {
            currentUser: {
              ...data.signup.user,
            },
          },
        });
      }
    },
  });

  useEffect(() => track('', 'Showed'), []);

  return (
    <Formik
      initialValues={{
        name: '',
        email: '',
        password: '',
      }}
      validate={values => {
        const errors = {};

        if (!values.name.trim()) {
          // @ts-ignore FIXME
          errors.name = "Can't be blank";
        }

        if (!isValidEmail(values.email)) {
          // @ts-ignore FIXME
          errors.email = 'Invalid email address';
        }

        if (values.password.length < 8) {
          // @ts-ignore FIXME
          errors.password = 'Too short';
        }

        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        track('Form', 'Submitted');
        resetForm();
        mutate({
          variables: {
            input: values,
          },
        })
          // @ts-ignore FIXME
          .then(({ data: { signup } }) => onFormSubmitSuccess(signup, values))
          .finally(() => setSubmitting(false));
      }}
      render={({ values, errors, touched, handleChange, isSubmitting }) => (
        <Container>
          <StyledTitle>{t('registration:signup.title')}</StyledTitle>
          <Note>{t('registration:signup.note')}</Note>
          <Form>
            <FormikForm>
              <InputContainer>
                <Input
                  name="name"
                  type="text"
                  placeholder={t('firstAndLastName')}
                  autoFocus={true}
                  autoComplete="off"
                  value={values.name}
                  onChange={handleChange}
                />

                {touched.name && errors.name && <InputError>{errors.name}</InputError>}
              </InputContainer>
              <InputContainer>
                <Input
                  name="email"
                  type="email"
                  placeholder={t('enterEmail')}
                  value={values.email}
                  onChange={handleChange}
                />

                {touched.email && errors.email && <InputError>{errors.email}</InputError>}
              </InputContainer>
              <InputContainer>
                <Input
                  name="password"
                  type="password"
                  placeholder={t('password')}
                  value={values.password}
                  onChange={handleChange}
                />

                {touched.password && errors.password && <InputError>{errors.password}</InputError>}

                <SignUpNote>{t('passwordNote')}</SignUpNote>
              </InputContainer>

              {signUpError && (
                <SignUpErrorContainer>
                  <SignUpError>{signUpError}</SignUpError>
                </SignUpErrorContainer>
              )}

              <Button disabled={isSubmitting} primary={true} type="submit">
                {t('signUp')}
              </Button>
            </FormikForm>
            <Delimiter />
            <InputContainer>
              <GoogleLoginContainer>
                <GoogleLogin
                  onClick={() => track('SignUpWithGoogleBtn', 'Clicked')}
                  onSuccess={onGoogleLoginSuccess}
                />
              </GoogleLoginContainer>
            </InputContainer>
          </Form>
        </Container>
      )}
    />
  );
};

SignUp.propTypes = {
  signUpError: PropTypes.string,
  onFormSubmitSuccess: PropTypes.func,
  onGoogleLoginSuccess: PropTypes.func,
};

export default compose(
  // @ts-ignore FIXME
  signUpRedirectContainer('signup'),
  // @ts-ignore FIXME
  withTranslation('common'),
  // @ts-ignore FIXME
)(SignUp);
