import React from 'react';
import styled from 'styled-components';
import Link from 'next/link';
import { useTranslation } from 'lib/i18n';
import { mediaSelector } from 'helpers/style-utils';

import { getCurrentUser } from 'components/users/WithCurrentUser/WithCurrentUser';
import Userpic from 'client/components/shared/Userpic/Userpic';
import Button from 'components/shared/Button/Button';
import { RegistrationViewsStackProps } from 'components/Registration/useRegistrationViewsStack';

import LogoWithTextIcon from 'components/v2/shared/svg/LogoWithTextIcon.svg';

const Container = styled.div`
  display: flex;
  align-items: center;
  height: 100px;
  padding: 0px 30px;
  justify-content: space-between;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1;

  ${mediaSelector.mobile} {
    background-color: ${p => p.theme.currentTheme.body};
  }
`;

const StyledLogo = styled(LogoWithTextIcon)`
  path {
    fill: ${p => p.theme.currentTheme.text};
  }
`;

const GoToButton = styled(Button)`
  ${mediaSelector.mobile} {
    height: 36px;
    margin-top: 4px;
    padding-top: 8px;
  }
`;

const Header: React.FC<RegistrationViewsStackProps> = ({ setView, currentView }) => {
  const { t } = useTranslation('registration');
  const currentUser = getCurrentUser();

  const showButton = currentView === 'resetPassword' || currentView === 'requestResetPassword';

  return (
    <Container>
      <StyledLogo />
      <div>
        {currentUser ? (
          <Link href="/">
            <a>
              <Userpic
                size={48}
                title={currentUser.name}
                image={currentUser.profileImage}
                isHoverable={true}
              />
            </a>
          </Link>
        ) : (
          <>
            {(currentView === 'signup' || showButton) && (
              <GoToButton flat={true} onClick={() => setView('signin')}>
                {t('header.signin')}
              </GoToButton>
            )}
            {currentView === 'signin' && (
              <GoToButton flat={true} onClick={() => setView('signup')}>
                {t('header.signup')}
              </GoToButton>
            )}
          </>
        )}
      </div>
    </Container>
  );
};

export default Header;
