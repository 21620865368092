import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form as FormikForm } from 'formik';
import { useMutation, gql } from '@apollo/client';
import styled from 'styled-components';
import { compose } from 'recompose';
import { useTranslation } from 'lib/i18n';
import { useTracking } from 'lib/track';

import { isValidEmail } from 'helpers/forms';
import { currentUserQuery } from 'components/users/WithCurrentUser/WithCurrentUser';
import currentUserFragment from 'components/users/WithCurrentUser/currentUserFragment';

import Input, { InputError } from 'components/Input/Input';
import Button from 'components/shared/Button/Button';
import Link from 'components/shared/Link/Link';
import { Container, Title, Note, Form, SignUpError } from 'components/Registration/View/View';
import GoogleLogin from 'components/Registration/GoogleLogin/GoogleLogin';
import signUpRedirectContainer from 'components/Registration/signUpRedirectContainer';

const StyledTitle = styled(Title)`
  font-size: 6.2rem;
  line-height: 5.6rem;
`;

const InputContainer = styled.div`
  margin-bottom: 20px;
`;

const SignInNote = styled.div`
  font-size: 1.4rem;
  color: ${p => p.theme.currentTheme.notes};
`;

const signInMutation = gql`
  mutation SignInMutation($input: SigninInput!) {
    signin(input: $input) {
      user {
        ...CurrentUserFragment
      }
      authToken {
        accessToken
        tokenType
      }
      refreshToken {
        token
        expireAt
      }
      error {
        code
      }
    }
  }
  ${currentUserFragment}
`;

// @ts-ignore FIXME
const SignIn = ({ signUpError, setView, resetForm, onFormSubmitSuccess, onGoogleLoginSuccess }) => {
  const { track } = useTracking('SignIn');
  const { t } = useTranslation(['common', 'registration']);
  const [mutate] = useMutation(signInMutation, {
    update: (cache, { data }) => {
      if (data?.signin?.user) {
        cache.writeQuery({
          query: currentUserQuery,
          data: {
            currentUser: {
              ...data.signin.user,
            },
          },
        });
      }
    },
  });

  useEffect(() => track('', 'Showed'), []);

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
      }}
      validate={values => {
        const errors: { [key: string]: string } = {};

        if (!isValidEmail(values.email)) {
          errors.email = t('invalidEmail');
        }

        if (values.password.length === 0) {
          errors.password = t('cantBeBlank');
        }

        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        track('Form', 'Submitted');
        resetForm();
        setSubmitting(false);
        mutate({
          variables: {
            input: values,
          },
        })
          // @ts-ignore FIXME
          .then(({ data: { signin } }) => {
            onFormSubmitSuccess(signin, values);
          })
          .finally(() => setSubmitting(false));
      }}
      render={({ values, errors, touched, handleChange, isSubmitting }) => (
        <Container>
          <StyledTitle>{t('registration:signin.title')}</StyledTitle>
          <Note>{t('registration:signin.note')}</Note>
          <Form>
            <FormikForm>
              <Container>
                <InputContainer>
                  <Input
                    name="email"
                    type="email"
                    placeholder={t('enterEmail')}
                    autoFocus={true}
                    value={values.email}
                    onChange={handleChange}
                  />

                  {touched.email && errors.email && <InputError>{errors.email}</InputError>}
                </InputContainer>
                <InputContainer>
                  <Input
                    name="password"
                    type="password"
                    placeholder={t('password')}
                    // @ts-ignore FIXME
                    value={values.password}
                    onChange={handleChange}
                  />

                  {touched.password && errors.password && (
                    <InputError>{errors.password}</InputError>
                  )}
                </InputContainer>

                {signUpError && (
                  <InputContainer>
                    <SignUpError>{signUpError}</SignUpError>
                  </InputContainer>
                )}

                <InputContainer>
                  <Button disabled={isSubmitting} primary={true} type="submit">
                    {t('signIn')}
                  </Button>
                </InputContainer>
              </Container>
            </FormikForm>
            <InputContainer>
              <GoogleLogin
                title={t('signInWithGoogle')}
                onClick={() => track('SignInGoogleBtn', 'Clicked')}
                onSuccess={onGoogleLoginSuccess}
              />
            </InputContainer>
            <InputContainer>
              <SignInNote>
                <Link
                  as="span"
                  onClick={() => {
                    setView('requestResetPassword');
                    track('RequestResetPassword', 'Clicked');
                  }}
                >
                  {t('registration:signin.request-reset-password')}
                </Link>
              </SignInNote>
            </InputContainer>
            <InputContainer>
              <SignInNote>
                {t('registration:signin.create-account-note')}{' '}
                <Link
                  as="span"
                  onClick={() => {
                    setView('signup');
                    track('CreateAnAcoount', 'Clicked');
                  }}
                >
                  {t('registration:signin.signup')}
                </Link>
                .
              </SignInNote>
            </InputContainer>
          </Form>
        </Container>
      )}
    />
  );
};

SignIn.propTypes = {
  signUpError: PropTypes.func,
  setView: PropTypes.func,
  onFormSubmitSuccess: PropTypes.func,
  onGoogleLoginSuccess: PropTypes.func,
};

export default compose(
  // @ts-ignore FIXME
  signUpRedirectContainer('signin'),
  // @ts-ignore FIXME
)(SignIn);
