import React, { useState } from 'react';

import FadeIn from 'components/FadeIn/FadeIn';
import { RegistrationViewsStackProps } from 'components/Registration/useRegistrationViewsStack';
import SignUp from './Views/SignUp/SignUp';
import SignIn from './Views/SignIn/SignIn';
import ConfirmEmail from './Views/ConfirmEmail/ConfirmEmail';
import UserInfo from './Views/UserInfo/UserInfo';
import UsageSelect from './Views/UsageSelect/UsageSelect';
import CreateWorkspace from './Views/CreateWorkspace/CreateWorkspace';
import RequestResetPassword from './Views/RequestResetPassword/RequestResetPassword';
import ResetPassword from './Views/ResetPassword/ResetPassword';

const VIEWS_MAP = {
  signin: SignIn,
  signup: SignUp,
  confirmEmail: ConfirmEmail,
  userInfo: UserInfo,
  usageSelect: UsageSelect,
  createWorkspace: CreateWorkspace,
  requestResetPassword: RequestResetPassword,
  resetPassword: ResetPassword,
};

const fadeInContainerStyles = {
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
};

type ViewProps = {
  viewProps?: any;
};

const Registration: React.FC<RegistrationViewsStackProps & ViewProps> = ({
  currentView,
  viewProps = {},
  ...props
}) => {
  const [state, setState] = useState({
    user: null,
    workspace: null,
    membership: null,
  });

  // @ts-ignore FIXME
  const View = VIEWS_MAP[currentView];

  if (!View) {
    return null;
  }

  return (
    <FadeIn key={currentView} style={fadeInContainerStyles}>
      <View
        currentView={currentView}
        // @ts-ignore FIXME
        setUser={user =>
          setState(prevState => ({
            ...prevState,
            user,
          }))
        }
        // @ts-ignore FIXME
        setWorkspace={workspace =>
          setState(prevState => ({
            ...prevState,
            workspace,
          }))
        }
        // @ts-ignore FIXME
        setMembership={membership =>
          setState(prevState => ({
            ...prevState,
            membership,
          }))
        }
        {...state}
        {...props}
        {...viewProps}
      />
    </FadeIn>
  );
};

export default Registration;
