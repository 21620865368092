import React, { useState } from 'react';
import { Formik, Form as FormikForm } from 'formik';
import styled from 'styled-components';
import { useTranslation, Trans } from 'lib/i18n';

import { isValidEmail } from 'helpers/forms';
import useRequestUserPasswordResetMutation from 'components/account/useRequestUserPasswordResetMutation';

import Input, { InputError } from 'components/Input/Input';
import Button from 'components/shared/Button/Button';
import { Container, Title, Note, Form } from 'components/Registration/View/View';

const InputContainer = styled.div`
  margin-bottom: 20px;
`;

const SuccessForm = styled(Form)`
  padding-top: 8px;
  max-width: 420px;
`;

const SuccessNote = styled(Note)`
  text-align: left;

  strong {
    color: ${p => p.theme.currentTheme.text};
  }
`;

const ResetPassword = () => {
  const [mutate] = useRequestUserPasswordResetMutation();
  const [email, setEmail] = useState('');
  const { t } = useTranslation(['common', 'registration']);

  return (
    <Formik
      initialValues={{
        email: '',
      }}
      validate={values => {
        const errors: { [key: string]: string } = {};

        if (!isValidEmail(values.email)) {
          errors.email = t('invalidEmail');
        }

        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        mutate({
          variables: {
            input: {
              email: values.email,
            },
          },
        })
          .then(() => setEmail(values.email))
          .catch(() => setSubmitting(false));
      }}
    >
      {({ values, touched, errors, setFieldValue, isSubmitting, isValid }) => {
        if (email) {
          return (
            <Container>
              <Title>{t('registration:request-reset-password.success.title')}</Title>
              <SuccessForm>
                <SuccessNote>
                  <Trans
                    i18nKey="registration:request-reset-password.success.note"
                    values={{ email }}
                  />
                </SuccessNote>
              </SuccessForm>
            </Container>
          );
        }

        return (
          <Container>
            <Title>{t('registration:request-reset-password.title')}</Title>
            <Note>{t('registration:request-reset-password.note')}</Note>
            <Form>
              <FormikForm>
                <InputContainer>
                  <Input
                    name="email"
                    type="email"
                    placeholder={t('enterEmail')}
                    autoFocus={true}
                    value={values.email}
                    onChange={(e: React.FormEvent<HTMLInputElement>) => {
                      const { value } = e.target as HTMLInputElement;

                      setFieldValue('email', value.toLowerCase());
                    }}
                  />
                </InputContainer>
                {touched.email && errors.email && (
                  <InputContainer>
                    <InputError>{errors.email}</InputError>
                  </InputContainer>
                )}
                <InputContainer>
                  <Button primary={true} disabled={!isValid || isSubmitting}>
                    {t('sendPasswordResetMail')}
                  </Button>
                </InputContainer>
              </FormikForm>
            </Form>
          </Container>
        );
      }}
    </Formik>
  );
};

export default ResetPassword;
