import React from 'react';
import { useApolloClient, ApolloClient } from '@apollo/client';

export type WithApolloClient<P> = P & {
  client: ApolloClient<object>;
};

const withApolloClient = <P extends {}>(
  Component: React.ComponentType<WithApolloClient<Omit<P, 'client'>>>,
) => (props: P) => {
  const client = useApolloClient();
  return <Component {...props} client={client} />;
};

export default withApolloClient;
