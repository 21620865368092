import React from 'react';
import InputLoadingContainer from './InputLoadingContainer';
import Input from './Input';

// @ts-ignore FIXME
const InputWithLoader = ({ loading, checked, ...inputProps }) => (
  <InputLoadingContainer loading={loading} checked={checked}>
    <Input {...inputProps} />
  </InputLoadingContainer>
);

export default InputWithLoader;
