import { gql, ApolloClient, NormalizedCacheObject } from '@apollo/client';

import {
  CreateProjectMembershipFromTokenMutation,
  CreateProjectMembershipFromTokenMutationVariables,
} from './__generated__/CreateProjectMembershipFromTokenMutation';

const createProjectMembershipFromToken = (
  client: ApolloClient<NormalizedCacheObject>,
  token: string,
) =>
  client.mutate<
    CreateProjectMembershipFromTokenMutation,
    CreateProjectMembershipFromTokenMutationVariables
  >({
    mutation: gql`
      mutation CreateProjectMembershipFromTokenMutation(
        $input: CreateProjectMembershipFromTokenInput!
      ) {
        createProjectMembershipFromToken(input: $input) {
          projectMembership {
            project {
              id
              slug
            }
            status
          }
        }
      }
    `,
    variables: {
      input: { token },
    },
  });

export default createProjectMembershipFromToken;
