import React from 'react';
import styled from 'styled-components';
import GoogleLoginButton from 'react-google-login';
import { useTranslation } from 'lib/i18n';

import config from 'config/env';

import { oauthCallback } from 'lib/withTokenMiddleware';

import { Button } from 'components/shared';
import GoogleIcon from 'components/shared/svg/GoogleIcon.svg';

const StyledButton = styled(Button)`
  display: inline-flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  padding: 0px;
`;

const Icon = styled(GoogleIcon)`
  margin-right: 8px;
`;

interface Props {
  title?: string;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  onSuccess: (data: any) => void;
}

const GoogleLogin: React.FC<Props> = ({ title, onClick = () => {}, onSuccess }) => {
  const { t } = useTranslation('common');
  const onGoogleLoginSuccess = (result: any) => oauthCallback(result).then(onSuccess);

  return (
    <GoogleLoginButton
      clientId={config.oauth.googleClientId}
      responseType="code"
      buttonText={t('login')}
      onSuccess={onGoogleLoginSuccess}
      onFailure={() => {
        // TODO: handle failure
      }}
      scope="email"
      render={loginProps => (
        <StyledButton
          preferInlineIconStyles={true}
          onClick={e => {
            if (loginProps) {
              loginProps.onClick();
            }
            onClick(e);
          }}
        >
          <Icon />
          {title || t('signupWithGoogle')}
        </StyledButton>
      )}
    />
  );
};

export default GoogleLogin;
