import { useEffect } from 'react';
import { getCookie, setCookie } from 'cookies-next';
import { expireCookie } from 'helpers/cookie';

import moment from 'moment';

import { useTracking } from 'lib/track';

/**
 * Temporary means for tracking successful registration
 *
 * Should be used at some point during registration process. This
 * cookie is later checked when logged in user sees index page
 * for the first time.
 */
export const useRegistrationTrackCookie = (invited: boolean) => {
  setCookie('signUpFromInvite', invited.toString(), {
    path: '/',
    expires: moment()
      .add(1, 'years')
      .toDate(),
  });
};

/**
 * Should be used to track successful registration when board on index page
 * is shown to logged in user for the first time. Only works if cookie from
 * useRegistrationTrackCookie is present
 */
export const useRegistrationTrack = () => {
  const { track } = useTracking('');
  const signUpFromInvite = getCookie('signUpFromInvite');

  useEffect(() => {
    if (signUpFromInvite) {
      track('SignUp', 'Successful', {
        invited: signUpFromInvite,
      });

      expireCookie('signUpFromInvite');
    }
  });
};
